<template>
  <v-card class="mx-auto" color="teal lighten-1">
    <v-card dark flat> </v-card>
    <v-card-text class="py-0">
      <v-timeline
        align-top
        dense
        v-for="event in about.timeline"
        :key="event.year"
        large
      >
        <v-timeline-item color="teal darken-3" small>
          <v-row class="pt-1">
            <v-col cols="1">
              <strong class="teal--text text--darken-4">{{
                event.year
              }}</strong>
            </v-col>

            <v-col>
              <strong class="white--text">{{ event.slogan }}</strong>
              <div class="caption">
                {{ event.description }}
              </div>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  title: "BA - About Us",
  computed: {
    about() {
      return this.$store.getters.about;
    },
  },
};
</script>
